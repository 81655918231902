export type IconOptionsType = {
  color: {
    black: string;
    blue: string;
    blue25: string;
    blue200: string;
    blue800: string;
    bluePrimary: string;
    deepReading: string;
    DEEPREADING: string;
    default: string;
    error700: string;
    error800: string;
    gray: string;
    gray100: string;
    gray300: string;
    gray350: string;
    gray400: string;
    gray50: string;
    gray500: string;
    gray600: string;
    gray700: string;
    gray900: string;
    brown300: string;
    brown700: string;
    green: string;
    lightGray: string;
    onboarding: string;
    ONBOARDING: string;
    orange: string;
    pink800: string;
    primary300: string;
    primary500: string;
    primary700: string;
    purple: string;
    red: string;
    relaxing: string;
    RELAXING: string;
    studying: string;
    STUDYING: string;
    success700: string;
    testing: string;
    TESTING: string;
    turquoise900: string;
    white: string;
    whiteSecondary: string;
    yellow800: string;
  };
  hoverColor: {
    gray700: string;
    primary500: string;
  };
  size: {
    xs: { width: number; height: number };
    xsm: { width: number; height: number };
    sm: { width: number; height: number };
    smd: { width: number; height: number };
    md: { width: number; height: number };
    mdl: { width: number; height: number };
    lg: { width: number; height: number };
    lgm: { width: number; height: number };
    lgx: { width: number; height: number };
    xl: { width: number; height: number };
    xxl: { width: number; height: number };
    '2lg': { width: number; height: number };
    '2xl': { width: number; height: number };
    '3xl': { width: number; height: number };
    'paymentMethod-sm': { width: number; height: number };
    'paymentMethod-md': { width: number; height: number };
    'paymentMethod-lg': { width: number; height: number };
    'book-pile': { width: number; height: number };

    'header-status-logo': { width: number; height: number };
    'learner-portal-logo': { width: number; height: number };
    'no-friends-search': { width: number; height: number };
    logoFull: { width: number; height: number };
    handred: { width: number; height: number };
    penguinLogo: { width: number; height: number };
    pretestLogo: { width: number; height: number };
    examPapersLogo: { width: number; height: number };
    parentRegistration: { width: number; height: number };
    registrationStep2Decorative: { width: number; height: number };
    wordCircle: { width: number; height: number };
    clock: { width: number; height: number };
    loadedClock: { width: number; height: number };
    clockSmall: { width: number; height: number };
    circleBar: { width: number; height: number };
    turtle: { width: number; height: number };
    rabbit: { width: number; height: number };
    jaguar: { width: number; height: number };
    'exam-header-logo': { width: number; height: number };
    'race-option-icon': { width: number; height: number };
    chatBot: { width: number; height: number };
    geogebra: { width: number; height: number };
    geogebraSmall: { width: number; height: number };
  };
};

export const icons = {
  'artist-palette': 'favoriteSubjects/artist-palette.png',
  'clapper-board': 'favoriteSubjects/clapper-board.png',
  add: 'plus.svg',
  addAlt: 'plusAlt.svg',
  addFlashcard: 'addFlashcard.svg',
  addAnnotation: 'addAnnotation.svg',
  addNotebook: 'addNotebook.svg',
  addReaction: 'addReaction.svg',
  addUser: 'addUser.svg',
  adduserIcon: 'adduserIcon.svg',
  adduserIconInactive: 'adduserIconInactive.svg',
  aeroplane: 'favoriteSubjects/aeroplane.png',
  aiAssessment: 'assessmentIcons/aiAssessment.svg',
  aiAvatar: 'aiAvatar.svg',
  AILiteracy: 'PartnerIcons/AILiteracy.svg',
  aiStudyGuide: 'pricingPlan/aiStudyGuide.svg',
  amex: 'amex.svg',
  annotationsActive: 'annotations-active.svg',
  annotationsInactive: 'annotations-inactive.svg',
  answer: `answer.svg`,
  apple: 'apple.svg',
  microsoft: 'microsoft.svg',
  arrowDownSlim: 'arrowDownSlim.svg',
  arrowLeft: 'arrowLeft.svg',
  arrowLong: 'arrowLong.svg',
  arrowPointer: 'arrowPointer.svg',
  arrowRight: 'arrowRight.svg',
  arrowRightButton: 'arrowRightButton.svg',
  assessment: 'assessment.svg',
  ATOMTEST: 'PartnerIcons/ATOMTEST.svg',
  avatarPlaceholder: 'avatarPlaceholder.svg',
  ball: 'favoriteSubjects/ball.png',
  bell: 'bellEmoji.svg',
  blueDottedLine: 'blueDottedLine.svg',
  book: 'book.svg',
  bookmark: 'bookmark.svg',
  bookmarkEmpty: 'bookmarkEmpty.svg',
  bookmarkFilled: 'bookmarkFilled.svg',
  books: 'favoriteSubjects/books.png',
  bracket: 'bracket.svg',
  brain: 'brain.svg',
  BrightonCollege: 'PartnerIcons/BrightonCollege.svg',
  bubble: 'bubble.svg',
  building: 'favoriteSubjects/building.png',
  calling: 'calling.svg',
  camera: 'camera.svg',
  cancel: 'cancel.svg',
  car: 'favoriteSubjects/car.png',
  case: 'favoriteSubjects/case.png',
  CAT4: 'PartnerIcons/CAT4.svg',
  CEM: 'PartnerIcons/CEM.svg',
  chat: 'chat.svg',
  chatBig: 'chat-big.svg',
  chatSmall: 'chatSmall.svg',
  check: 'check.png',
  checkedRadio: 'checkedRadio.svg',
  checkExam: 'checkExam.svg',
  checkmark: 'checkmark.svg',
  checkmarkBold: 'checkmarkBold.svg',
  checkmarkActive: 'checkmarkActive.svg',
  checkmarkAlt: 'checkmarkAlt.svg',
  chevronLeft: 'chevronLeft.svg',
  chevronRight: 'chevronRight.svg',
  circleArrowDown: 'circleArrowDown.svg',
  circleArrowEmpty: 'circleArrowEmpty.svg',
  circleArrowUp: 'circleArrowUp.svg',
  circleCancel: 'circleCancel.svg',
  circledBullet: 'circledBullet.svg',
  circleEmpty: 'circleEmpty.svg',
  circleFull: 'circleFull.svg',
  classwork: 'teacher/classwork.svg',
  clock: 'clock.svg',
  clockPrimary: 'clockPrimary.svg',
  close_png: 'close.png',
  close: 'close.svg',
  closeSquare: 'closeSquare.svg',
  coffee: 'coffee.svg',
  companyLogo: 'companyLogo.svg',
  computer: 'favoriteSubjects/computer.png',
  Consortium11: 'PartnerIcons/Consortium11.svg',
  cool: 'studyGuide/cool.svg',
  copyContent: 'copyContent.svg',
  copyContentBolder: 'copyContentBolder.svg',
  correctAnswer: 'correctAnswer.svg',
  cross: 'cross.svg',
  crossAlt: 'crossAlt.svg',
  crossCancel: 'crossCancel.svg',
  CSSE: 'PartnerIcons/CSSE.svg',
  FSCE: 'PartnerIcons/FSCE.svg',
  '11_Practice': 'PartnerIcons/11_Practice.svg',
  ETONY6: 'PartnerIcons/ETONY6.svg',
  ctAssessment: 'assessmentIcons/ctAssessment.svg',
  culture: 'favoriteSubjects/culture.png',
  danger: 'danger.svg',
  dangerFilledTriangle: 'dangerFilledTriangle.svg',
  dash: 'dash.svg',
  decrease: 'decrease.svg',
  deepReader: 'pricingPlan/deepReader.svg',
  defaultStudyGuide: 'studyGuide/defaultStudyGuide.svg',
  delete: 'delete.svg',
  deleteBold: 'deleteBold.svg',
  deleteSmall: 'deleteSmall.svg',
  diagnosticAssessments: 'pricingPlan/diagnosticAssessments.svg',
  diagnosticAssessmentsOrange: 'pricingPlan/diagnosticAssessmentsOrange.svg',
  diners: 'diners.svg',
  discoball: 'favoriteSubjects/discoball.png',
  discover: 'discover.svg',
  dislike: 'dislike.svg',
  dislikeRating: 'dislikeRating.svg',
  dog: 'favoriteSubjects/dog.png',
  door: 'door.svg',
  dot: 'dot.svg',
  dottedLine: 'dottedLine.svg',
  downArrow: 'downArrow.svg',
  downArrowBold: 'downArrowBold.svg',
  downArrowLong: 'downArrowLong.svg',
  download: 'download.svg',
  DulwichCollege: 'PartnerIcons/DulwichCollege.svg',
  edit: 'edit.svg',
  editBold: 'editBold.svg',
  editSquare: 'editSquare.svg',
  ellipse: 'ellipse.svg',
  email: 'email.svg',
  emailNewStyle: 'emailNewStyle.svg',
  emptyAvatar: 'emptyAvatar.svg',
  emptyRadio: 'emptyRadio.svg',
  emptySpot: 'emptySpot.svg',
  englishAssessment: 'assessmentIcons/englishAssessment.svg',
  EtonCollege: 'PartnerIcons/EtonCollege.svg',
  euFlag: 'euFlag.svg',
  examContainer: 'examIconContainer.svg',
  examHead: 'examHead.svg',
  examPaperPlus: 'examPaperPlus.svg',
  examPapersLogo: 'examPapersLogo.svg',
  examPapersPlus: 'examPapersPlus.svg',
  eye: 'eye.svg',
  eyeHide: 'eyeHide.svg',
  eyes: 'eyes.svg',
  eyeShow: 'eyeShow.svg',
  face_with_tears_of_joy: 'aiChat/face_with_tears_of_joy.svg',
  facebook: 'facebook.svg',
  filter2: 'filter2.svg',
  fire: 'fire.svg',
  flame: 'flame.svg',
  flameRed: 'flameRed.svg',
  flameGray: 'flameGray.svg',
  diamond: 'diamond.svg',
  diamondYellow: 'topicAccuracy/diamondYellow.svg',
  starYellow: 'topicAccuracy/starYellow.svg',
  starGray: 'topicAccuracy/starGray.svg',
  heartYellow: 'topicAccuracy/heartYellow.svg',
  aiLiteracyAIAndRobots: 'aiLiteracyAIAndRobots.svg',
  star2: 'star2.svg',
  bell2: 'bell2.svg',
  flashCard: 'flashCard.svg',
  flashcardMaker: 'pricingPlan/flashcardMaker.svg',
  flashcards: 'flashcards.svg',
  flashcards2: 'flashcards2.svg',
  flippedClassroom: 'pricingPlan/flippedClassroom.svg',
  footerMessage: 'footerMessage.svg',
  forwardArrow: 'forwardArrow.svg',
  Francis: 'PartnerIcons/Francis.svg',
  friends: 'friends.svg',
  FSCE11PLUS: 'PartnerIcons/FSCE11PLUS.svg',
  fullScreen: 'fullScreen.svg',
  collapseFullScreen: 'collapseFullScreen.svg',
  gamepad: 'favoriteSubjects/gamepad.png',
  gbFlag: 'gbFlag.svg',
  geekAvatar: 'geekAvatar.svg',
  generic: 'generic.svg',
  GL11: 'PartnerIcons/GL11.svg',
  Godolphin: 'PartnerIcons/Godolphin.svg',
  google: 'google.svg',
  googleColored: 'googleColored.svg',
  gpt: 'favoriteSubjects/gpt.png',
  graduationCap: 'graduationCapEmoji.svg',
  grayHappyFace: 'grayHappyFace.svg',
  greenTick: 'greenTick.svg',
  GuildfordHigh: 'PartnerIcons/GuildfordHigh.svg',
  hamburger: 'hamburger.svg',
  hamburgerActive: 'hamburger-active.svg',
  hamburgerInactive: 'hamburger-inactive.svg',
  hands: 'favoriteSubjects/hands.png',
  handShake: 'handShake.svg',
  HarrowSchool: 'PartnerIcons/HarrowSchool.svg',
  hatStudent: 'hatStudent.svg',
  herb: 'favoriteSubjects/herb.png',
  hide: 'hide.svg',
  highClock: 'morphologyReviewClock/highClock.svg',
  history: 'history.svg',
  homework: 'teacher/homework.svg',
  inCorrectAnswer: 'inCorrectAnswer.svg',
  increase: 'increase.svg',
  infinity: 'infinity.svg',
  info: 'info.svg',
  infoCircleFill: 'infoCircleFill.svg',
  infoCircleBold: 'infoCircleBold.svg',
  infoLight: 'infoLight.svg',
  infoOutlined: 'infoOutlined.svg',
  inputField: 'inputField.svg',
  instagram: 'instagram.svg',
  inviteQr: 'inviteQr.svg',
  ISEB: 'PartnerIcons/ISEB.svg',
  jaguar: 'jaguar.svg',
  jaguarDisabled: 'jaguarDisabled.svg',
  jcb: 'jcb.svg',
  knowledge: 'knowledge.svg',
  backgroundKnowledge: 'backgroundKnowledge.svg',
  Latymer: 'PartnerIcons/Latymer.svg',
  learnerDashboard: 'learnerDashboard.svg',
  left: 'left.svg',
  leftArrow: 'leftArrow.svg',
  lessonOverview: 'lessonOverview.svg',
  like: 'like.svg',
  likeRating: 'likeRating.svg',
  linkedIn: 'linkedIn.svg',
  loadedClock: 'loadedClock.svg',
  lock: 'lock.svg',
  lockerIcon: 'lockerIcon.svg',
  logo: 'logo.svg',
  logoFull: 'logoFull.svg',
  logoGlasses: 'logoGlasses.svg',
  logoReading: 'logoReading.svg',
  logoSmile: 'logoSmile.svg',
  logoSmileBlue: 'logoSmileBlue.svg',
  logout: 'logout.svg',
  logoWithBook: 'logoWithBook.svg',
  logoWithWriteBook: 'logoWithWriteBook.svg',
  LondonGirls: 'PartnerIcons/LondonGirls.svg',
  lowClock: 'morphologyReviewClock/lowClock.svg',
  magnifier: 'magnifier.svg',
  magnifierAlt: 'magnifierAlt.svg',
  magnifyingGlass: 'magnifyingGlass.svg',
  map: 'favoriteSubjects/map.png',
  masterCard: 'masterCard.svg',
  mastercard: 'mastercard.svg',
  mathsAssessment: 'assessmentIcons/mathsAssessment.svg',
  medal: 'favoriteSubjects/medal.png',
  mediumClock: 'morphologyReviewClock/mediumClock.svg',
  message: 'message.svg',
  microscope: 'favoriteSubjects/microscope.png',
  modesOff: 'modesOff.svg',
  modesOn: 'modesOn.svg',
  moneybag: 'favoriteSubjects/moneybag.png',
  morphologyMaker: 'pricingPlan/morphologyMaker.svg',
  neutralRating: 'neutralRating.svg',
  newspaper: 'favoriteSubjects/newspaper.png',
  nextStep: 'nextStep.svg',
  notebook: 'notebook.svg',
  notes: 'favoriteSubjects/notes.png',
  notification: 'notification.svg',
  noTopicAccuracyScore: 'noTopicAccuracyScore.svg',
  nvrAssessment: 'assessmentIcons/nvrAssessment.svg',
  overdue: 'overdue.svg',
  page: 'page.svg',
  paper: 'paper.svg',
  paper2: 'paper2.svg',
  parents: 'parents.svg',
  passwordIcon: 'passwordIcon.svg',
  patrickD: 'testimonials/patrickD.png',
  pause: 'pause.svg',
  pauseBold: 'pauseBold.svg',
  pending: 'pending.svg',
  penguinHouse: 'penguinHouse.svg',
  penguinLogo: 'penguinLogo.svg',
  penguinRandom: 'penguinRandomHouse.svg',
  personalisedStudyPlan: 'pricingPlan/personalisedStudyPlan.svg',
  personalizedContent: 'pricingPlan/personalizedContent.svg',
  picture: 'picture.svg',
  pile_of_poo: 'aiChat/pile_of_poo.svg',
  pin: 'pin.svg',
  pinEmpty: 'pinEmpty.svg',
  pinWhite: 'pinWhite.svg',
  pizza: 'favoriteSubjects/pizza.png',
  plane: 'plane.svg',
  airplane: 'airplane.svg',
  timerColored: 'timerColored.svg',
  planet: 'favoriteSubjects/planet.png',
  play: 'play.svg',
  plus: 'plus.svg',
  plusBold: 'plusBold.svg',
  plusButton: 'plusButton.svg',
  podcast: 'podcast.svg',
  pointedStar: 'pointedStar.svg',
  premiumContent: 'pricingPlan/premiumContent.svg',
  pretestLogo: 'pretestLogo.svg',
  pretestPlus: 'pretestPlus.svg',
  previousStep: 'previousStep.svg',
  pricingArrow: `pricingArrow.svg`,
  print: 'print.svg',
  profile: 'profile.svg',
  profileActive: 'profileActive.svg',
  profilePending: 'profilePending.svg',
  qrAssessment: 'assessmentIcons/qrAssessment.svg',
  question: 'question.svg',
  quiz: 'quiz.svg',
  rabbit: 'rabbit.svg',
  rabbitDisabled: 'rabbitDisabled.svg',
  radioColoredSelected: 'radioColoredSelected.svg',
  radioCorrect: 'radioCorrect.svg',
  radioIncorrect: 'radioIncorrect.svg',
  radioNotSelected: 'radioNotSelected.svg',
  radioSelected: 'radioSelected.svg',
  recommendation: 'recommendation.png',
  redFire: 'redFire.svg',
  regenerate: 'regenerate.svg',
  replay: 'replay.svg',
  resume: 'resume.svg',
  returnArrow: 'returnArrow.svg',
  right: 'right.svg',
  rightArrow: 'rightArrow.svg',
  rocket: 'favoriteSubjects/rocket.png',
  RugbySchool: 'PartnerIcons/RugbySchool.svg',
  sad: 'studyGuide/sad.svg',
  SATs: 'PartnerIcons/SATs.svg',
  schoolDataArrow: 'schoolDataArrow.svg',
  schooleTeacher: 'schooleTeacher.svg',
  scienceOfLearnerCards: 'scienceOfLearnerCards.svg',
  search: 'search.svg',
  searchBlue: 'searchBlue.svg',
  sendInvite: 'sendInvite.svg',
  SET: 'PartnerIcons/SET.svg',
  settings: 'settings.svg',
  shoe: 'favoriteSubjects/shoe.png',
  showBold: 'showBold.svg',
  shuffle: 'shuffle.svg',
  skip: 'skip.svg',
  slimArrowDown: 'slimArrowDown.svg',
  slimArrowUp: 'slimArrowUp.svg',
  smart: 'smart.svg',
  smile: 'favoriteSubjects/smile.png',
  smileFaceLarge: 'smileFaceLarge.svg',
  smiling_face: 'aiChat/smiling_face.svg',
  sortaz: 'sortaz.svg',
  sortza: 'sortza.svg',
  sound: 'sound.svg',
  sparkling_heart: 'aiChat/sparkling_heart.svg',
  spinner: 'spinner.svg',
  srAssessment: 'assessmentIcons/nvrAssessment.svg',
  star: 'star.svg',
  starEmpty: 'starEmpty.svg',
  starIcon: 'starIcon.svg',
  starIconInactive: 'starIconInactive.svg',
  starRating: 'starRating.svg',
  starRatingHover: 'starRatingHover.svg',
  status: 'status.svg',
  stop: 'stop.svg',
  StPauls: 'PartnerIcons/StPauls.svg',
  StPaulsGirls: 'PartnerIcons/StPaulsGirls.svg',
  study: 'study.svg',
  studyCoins: 'studyCoins.svg',
  studyGroup: 'studyGroup.svg',
  studyGroupArrow: 'studyGroupArrow.svg',
  studyGroups: 'pricingPlan/studyGroups.svg',
  studyGroupStudy: 'studyGroupStudy.svg',
  studyGuide: 'studyGuide.svg',
  studyguideArrow: 'studyguideArrow.svg',
  studyLearner: 'studyLearner.svg',
  successCircle: 'successCircle.svg',
  superhero: 'favoriteSubjects/superhero.png',
  swap: 'swap.svg',
  tableOfContent: 'tableOfContent.svg',
  target: 'target.svg',
  threeUser: 'threeUser.svg',
  thumbs_down: 'aiChat/thumbs_down.svg',
  thumbs_up: 'aiChat/thumbs_up.svg',
  thumbUp: 'thumbUp.svg',
  tickSquare: 'tickSquare.svg',
  TIFFIN: 'PartnerIcons/TIFFIN.svg',
  timer: 'timer.svg',
  time: 'time.svg',
  toDoArrow: 'toDoArrow.svg',
  toDoCheckmark: 'toDoCheckmark.svg',
  toDoCurve: 'toDoCurve.svg',
  trashCan: 'trashCan.svg',
  turtle: 'turtle.svg',
  turtleDisabled: 'turtleDisabled.svg',
  turtleNew: 'turtleNew.svg',
  twitter: 'twitter.svg',
  twoUsers: 'twoUsers.svg',
  unionpay: 'unionpay.svg',
  upArrow: 'upArrow.svg',
  upArrowBold: 'upArrowBold.svg',
  upArrowLong: 'upArrowLong.svg',
  upload: 'upload.svg',
  uploadFile: 'uploadFile.svg',
  users: 'users.svg',
  usFlag: 'usFlag.svg',
  vennDiagram: 'vennDiagram.svg',
  visa: 'visa.svg',
  vocabularyBuilder: 'pricingPlan/vocabularyBuilder.svg',
  voice: 'voice.svg',
  volumeOff: 'volumeOff.svg',
  vrAssessment: 'assessmentIcons/vrAssessment.svg',
  wallet: 'wallet.svg',
  warning: 'warning.svg',
  warningEmoji: 'warningEmoji.svg',
  Wellington: 'PartnerIcons/Wellington.svg',
  Westminster: 'PartnerIcons/Westminster.svg',
  wikipediaLogo: 'wikipediaLogo.svg',
  wonde: 'wonde.svg',
  clappingHands: 'deepWriter/clappingHands.svg',
  coldFace: 'deepWriter/coldFace.svg',
  faceWithMonocle: 'deepWriter/faceWithMonocle.svg',
  hotFace: 'deepWriter/hotFace.svg',
  hundredPoints: 'deepWriter/hundredPoints.svg',
  pileOfPoo: 'deepWriter/pileOfPoo.svg',
  sparklingHeart: 'deepWriter/sparklingHeart.svg',
  starStruck: 'deepWriter/starStruck.svg',
  thinkingFace: 'deepWriter/thinkingFace.svg',
  thumbUp2: 'deepWriter/thumbUp2.svg',
  wordMaps: 'wordMaps.svg',
  writepin: 'writepin.svg',
  youtube: 'youtube.svg',
  arrowDownCircle: 'arrowDownCircle.svg',
  arrowUpCircle: 'arrowUpCircle.svg',
  arrowUpGreenCircle: 'arrowUpGreenCircle.svg',
  arrowDownRedCircle: 'arrowDownRedCircle.svg',
  aiAssessmentNew: 'assessmentIcons/aiAssessmentNew.svg',
  ctAssessmentNew: 'assessmentIcons/ctAssessmentNew.svg',
  englishAssessmentNew: 'assessmentIcons/englishAssessmentNew.svg',
  mathsAssessmentNew: 'assessmentIcons/mathsAssessmentNew.svg',
  nvrAssessmentNew: 'assessmentIcons/nvrAssessmentNew.svg',
  qrAssessmentNew: 'assessmentIcons/qrAssessmentNew.svg',
  srAssessmentNew: 'assessmentIcons/srAssessmentNew.svg',
  vrAssessmentNew: 'assessmentIcons/vrAssessmentNew.svg',
  punctuationTopic: 'topics/punctuationTopic.svg',
  spellingTopic: 'topics/spellingTopic.svg',
  grammarTopic: 'topics/grammarTopic.svg',
  otherTopic: 'topics/otherTopic.svg',
  mockExam: 'topics/mockExam.svg',
  pulsatingDot: 'gifs/pulsatingDot.gif',
  fcPink: 'flashcards/fcPink.svg',
  fcOrange: 'flashcards/fcOrange.svg',
  fcYellow: 'flashcards/fcYellow.svg',
  fcGreen: 'flashcards/fcGreen.svg',
  fcBlue: 'flashcards/fcBlue.svg',
  fcTurquoise: 'flashcards/fcTurquoise.svg',
  fcPurple: 'flashcards/fcPurple.svg',
  bookPink: 'flashcards/bookPink.svg',
  bookOrange: 'flashcards/bookOrange.svg',
  bookYellow: 'flashcards/bookYellow.svg',
  bookGreen: 'flashcards/bookGreen.svg',
  bookBlue: 'flashcards/bookBlue.svg',
  bookTurquoise: 'flashcards/bookTurquoise.svg',
  bookPurple: 'flashcards/bookPurple.svg',
  highlights: 'quiz/highlights.svg',
  shortAnswer: 'quiz/shortAnswer.svg',
  multipleChoice: 'quiz/multipleChoice.svg',
  trueOrFalse: 'quiz/trueOrFalse.svg',
  calendar: 'calendar.svg',
  stanineGrid: 'stanineGrid.svg',
  dakotaBot: 'dakotaBot.svg',
  purpleRibbonMedal: 'topicAccuracy/purpleRibbonMedal.svg',
  redRibbonMedal: 'topicAccuracy/redRibbonMedal.svg',
  greenRibbonMedal: 'topicAccuracy/greenRibbonMedal.svg',
  grayRibbonMedal: 'topicAccuracy/grayRibbonMedal.svg',
  dots: 'dots.svg',
  streakRedFlame: 'streakRedFlame.svg',
  longArrow: 'longArrow.svg',
  longArrowLeft: 'longArrowLeft.svg',
  ticketStar: 'ticketStar.svg',
  starBold: 'starBold.svg',
  aiEmoji: 'aiEmoji.svg',
  ctEmoji: 'ctEmoji.svg',
  englishEmoji: 'englishEmoji.svg',
  mathsEmoji: 'mathsEmoji.svg',
  nvrEmoji: 'nvrEmoji.svg',
  qrEmoji: 'qrEmoji.svg',
  srEmoji: 'srEmoji.svg',
  vrEmoji: 'vrEmoji.svg',
  assigned: 'assigned.svg',
  scheduled: 'scheduled.svg',
  draft: 'draft.svg',
  archived: 'archived.svg',
  geogebraLogo: 'geogebra/geogebraLogo.svg',
  skill_770: 'geogebra/skill_770.svg',
  skill_771: 'geogebra/skill_771.svg',
  skill_772: 'geogebra/skill_772.svg',
  skill_773: 'geogebra/skill_773.svg',
  arrowCollapseLeft: 'arrowCollapseLeft.svg',
  arrowCollapseRight: 'arrowCollapseRight.svg',
  comment: 'comment.svg',
  commentOrange: 'dakotaActions/commentOrange.svg',
  faq: 'dakotaActions/faq.svg',
  lifebuoy: 'dakotaActions/lifebuoy.svg',
  plusGreen: 'dakotaActions/plusGreen.svg',
  plusPinkFilled: 'dakotaActions/plusPinkFilled.svg',
  checkGreenDr: 'dakotaActions/checkGreen.svg',
  deleteDr: 'dakotaActions/delete.svg',
  calendarDr: 'dakotaActions/calendar.svg',
  groupDr: 'dakotaActions/group.svg',
  reuseDr: 'dakotaActions/reuse.svg',
  geogebraIcon: 'topics/geogebraIcon.svg',
  highlightHint: 'dakotaFleshcardsPanel/highlight.svg',
  textColorHint: 'dakotaFleshcardsPanel/textColor.svg',
  underlineHint: 'dakotaFleshcardsPanel/underline.svg',
  wordHint: 'dakotaFleshcardsPanel/word.svg',
  editHint: 'dakotaFleshcardsPanel/editHint.svg',
  AgeoIcon: 'geogebra/AgeoIcon.svg',
  ASMDgeoIcon: 'geogebra/ASMDgeoIcon.svg',
  CUgeoIcon: 'geogebra/CUgeoIcon.svg',
  FDPgeoIcon: 'geogebra/FDPgeoIcon.svg',
  NPVgeoIcon: 'geogebra/NPVgeoIcon.svg',
  PAVgeoIcon: 'geogebra/PAVgeoIcon.svg',
  PDgeoIcon: 'geogebra/PDgeoIcon.svg',
  PSgeoIcon: 'geogebra/PSgeoIcon.svg',
  SgeoIcon: 'geogebra/SgeoIcon.svg',
  TgeoIcon: 'geogebra/TgeoIcon.svg',
  quizzesDr: 'deepReaderChat/quizzes.svg',
  annotationsDr: 'deepReaderChat/annotations.svg',
  knowledgeDr: 'deepReaderChat/knowledge.svg',
  flashcardsDr: 'deepReaderChat/flashcards.svg',
  newThreadDr: 'deepReaderChat/newThread.svg',
  paperclip: 'paperclip.svg',
  flag: 'flag.svg',
  sidebar: 'sidebar.svg',
  NETgeoIcon: 'geogebra/NETgeoIcon.svg',
  graduateHat: 'graduateHat.svg',
  shLogo: 'shLogo.svg',
  drawerOpener: 'drawerOpener.svg',
  mistakesIcon: 'mistakesIcon.svg',
  mistakesXIcon: 'mistakesXIcon.svg',
  mockIcon: 'mockIcon.svg',
  todoIcon: 'todoIcon.svg',
  flashcardsLibraryIcon: 'flashcardsLibraryIcon.svg',
  flashcardIcon: 'flashcardIcon.svg',
  friendsIcon: 'friendsIcon.svg',
  myFriends: 'myFriends.svg',
  addFriends: 'addFriends.svg',
  profileIcon: 'profileIcon.svg',
  examsTargetIcon: 'examsTargetIcon.svg',
  notificationSettingsIcon: 'notificationSettingsIcon.svg',
  researchIcon: 'researchIcon.svg',
  resourcesIcon: 'resourcesIcon.svg',
  resources: 'resources.svg',
  resultsIcon: 'resultsIcon.svg',
  syllabusIcon: 'syllabusIcon.svg',
  pedPromptAssessmentNew: 'assessmentIcons/pedPromptAssessmentNew.svg',
  paymentMethodIcon: 'paymentMethod.svg',
  subscriberManagementIcon: 'subscriberManagement.svg',
  letItGo: 'letItGo.svg',
  logoSmileGradient: 'logoSmileGradient.svg',
  infoCircleBlue: 'infoCircleBlue.svg',
  schoolIcon: 'schoolIcon.svg',
  proofReaderIcon: 'proofReaderIcon.svg',
  calendarIcon: 'calendarIcon.svg',
  challengeIcon: 'challenge.svg',
  inviteFriendsIcon: 'inviteFriendsIcon.svg',
  flashcardBlue: 'flashcardBlue.svg',
  comingSoon: 'comingSoon.svg',
  comingSoonClock: 'comingSoonClock.svg',
  starOutline: 'star_outline.svg',
  starFilled: 'star_filled.svg',
  writingIcon: 'writingIcon.svg',
  studyTools: 'studyTools.svg',
  flashcardBlueIcon: 'flashcardBlue.svg',
  wordGamesIcon: 'wordGamesIcon.svg',
  folderIcon: 'folderIcon.svg',
  builderIcon: 'builderIcon.svg',
  dashboardIcon: 'dashboardIcon.svg',
  studentsIcon: 'studentsIcon.svg',
  assignmentsIcon: 'assignmentsIcon.svg',
  diagnosticIcon: 'diagnosticIcon.svg',
  assignmentBuilderIcon: 'assignmentBuilderIcon.svg',
  allStudentsIcon: 'allStudentsIcon.svg',
  studyGroupsIcon: 'studyGroupsIcon.svg',
  staffIcon: 'staffIcon.svg',
  focus: 'focus.svg',
  dottedEllipse: 'dottedEllipse.svg',
  gradientEllipse: 'gradientEllipse.svg',
  hundredPercent: 'hundredPercent.svg',
  arrowLongLeft: 'arrowLongLeft.svg',
  calendarIconNew: 'calendarIconNew.svg',
  communityIcon: 'communityIcon.svg',
  aiSuperfoodIcon: 'aiSuperfoodIcon.svg',
  infoCircleFilled: 'infoCircleFilled.svg',
  likeOutline: 'likeOutline.svg',
  welcomeIcons: 'welcomeIcons.svg',
  parentStep: 'parentStep.svg',
  childrenIcon: 'childrenIcon.svg',
  handredPercent: 'handredPercent.svg',
  needsIcon: 'needsIcon.svg',
  focusIcon: 'focusIcon.svg',
  learningIcon: 'learningIcon.svg',
  newFeaturesIcon: 'newFeaturesIcon.svg',
  coinsIcon: 'coinsIcon.svg',
  giftIcon: 'giftIcon.svg',
  notebookBG: 'notebookBG.svg',
  adminIcon: 'adminIcon.svg',
  familyIcon: 'familyIcon.svg',
  homeLogin: 'homeLogin.svg',
  learnerIcon: 'learnerIcon.svg',
  schoolLogin: 'schoolLogin.svg',
  studentIcon: 'studentIcon.svg',
  teacherIcon: 'teacherIcon.svg',
  passwordImageIcon: 'passwordImageIcon.svg',
  keyIcon: 'keyIcon.svg',
  interestsIcon: 'interestsIcon.svg',
  cameraIcon: 'cameraIcon.svg',
  emailIcon: 'emailIcon.svg',
  innovationIcon: 'innovationIcon.svg',
  verifiedAccount: 'verifiedAccount.svg',
  takeQuiz: 'takeQuiz.svg',
  learningGoalsIcon: 'learningGoalsIcon.svg',
  targetGoal: 'targetGoal.svg',
  challengesIcon: 'challengesIcon.svg',
  questionsIcon: 'questionsIcon.svg',
  studyMinutesIcon: 'studyMinutesIcon.svg',
  vocabularyIcon: 'vocabularyIcon.svg',
  brokenHeartZpd: 'zpd/brokenHeart.svg',
  puzzledHeartZpd: 'zpd/puzzledHeart.svg',
  bookZpd: 'zpd/book.svg',
  rocketOutline: 'rocketOutline.svg',
  checkGreen: 'checkGreen.svg',
  launchpadImage: 'launchpadImage.svg',
  readingIcon: 'readingIcon.svg',
  downloadPDF: 'downloadPDF.svg',
  downloadXLS: 'downloadXLS.svg',
  resend: 'resend.svg',
  booksReadIcon: 'booksReadIcon.svg',
  zpdIcon: 'zpdIcon.svg',
  bookTagsSearch: 'bookTagsSearch.svg',
  aiApple: 'aiApple.svg',
  berry: 'berry.svg',
  bestTime: 'bestTime.svg',
  cookie: 'cookie.svg',
  game: 'game.svg',
  wordGamer: 'wordGamer.svg',
  plus5: 'plus5.svg',
  creativeComprehension: 'subject/creativeComprehension.svg',
  geometry: 'subject/geometry.svg',
  language: 'subject/language.svg',
  measurement: 'subject/measurement.svg',
  numbers: 'subject/numbers.svg',
  nvr: 'subject/nvr.svg',
  problemSolving: 'subject/problemSolving.svg',
  quantitativeReasoning: 'subject/quantitativeReasoning.svg',
  reading: 'subject/reading.svg',
  spatialReasoning: 'subject/spatialReasoning.svg',
  statistics: 'subject/statistics.svg',
  writing: 'subject/writing.svg',
  vr: 'subject/vr.svg',
  scoreLevel1: 'scoreLevel1.svg',
  scoreLevel2: 'scoreLevel2.svg',
  scoreLevel3: 'scoreLevel3.svg',
  scoreLevel4: 'scoreLevel4.svg',
  scoreLevel5: 'scoreLevel5.svg',
  scoreLevel6: 'scoreLevel6.svg',
  scoreLevel7: 'scoreLevel7.svg',
  scoreLevel8: 'scoreLevel8.svg',
  scoreLevel9: 'scoreLevel9.svg',
  superIntelligence: 'superIntelligence.svg',
  targetPlain: 'targetPlain.svg',
  targetBlue: 'targetBlue.svg',
  targetGreen: 'targetGreen.svg',
  targetOrange: 'targetOrange.svg',
  targetPurple: 'targetPurple.svg',
  targetRed: 'targetRed.svg',
  done: 'done.svg',
  adminTabIcon: 'adminTabIcon.svg',
  mockGradient: 'mockGradient.svg',
  todoGradient: 'todoGradient.svg',
  level: 'level.svg',
  strikethrough: 'strikethrough.svg',
  pathHorizontal: 'pathHorizontal.svg',
  pathVertical: 'pathVertical.svg',
  pathLeftBottom: 'pathLeftBottom.svg',
  pathLeftTop: 'pathLeftTop.svg',
  pathRightBottom: 'pathRightBottom.svg',
  pathRightTop: 'pathRightTop.svg',
  triangleBg: 'triangleBg.svg',
  ccEmoji: 'ccEmoji.svg',
  pspEmoji: 'pspEmoji.svg',
  library: 'library.svg',
  article: 'article.svg',
  MITSmall: 'MITSmall.svg',
  MITBig: 'MITBig.svg',
  MITPlain: 'MITPlain.svg',
  arrowRightPurple: 'arrowRightPurple.svg',
  ArrowRightBlue: 'ArrowRightBlue.svg',
  tiIcon: 'tiIcon.svg',
  schoolBoard: 'schoolBoard.svg',
  shrtyIcon: 'shrtyIcon.svg',
  bookZpdIcon: 'bookZpdIcon.svg',
  readingAgeIcon: 'readingAgeIcon.svg',
  levelIcon: 'levelIcon.svg',
  stanine: 'stanine.svg',
  examsSet: 'examsSet.svg',
  plusBorderedOutline: 'plusBorderedOutline.svg',
  newAssignment: 'newAssignment.svg',
  arrowGrowth: 'arrowGrowth.svg',
  subjectAll: 'subjectAll.svg',
  partial: 'partial.svg',
  flashcard3: 'flashcard3.svg',
  articlesIcon: 'articlesIcon.svg',
  quizzesIcon: 'quizzesIcon.svg',
  libraryIcon: 'libraryIcon.svg',
  findBookIcon: 'findBookIcon.svg',
  searchCheck: 'searchCheck.svg',
  startBookTalk: 'startBookTalk.svg',
  clozeSentence: 'clozeSentence.svg',
  aiGiveMeWords: 'aiGiveMeWords.svg',
  starOrangeFilled: 'starOrangeFilled.svg',
  dakotaPurpleGradient: 'dakotaPurpleGradient.svg',
  myBooksIcon: 'myBooksIcon.svg',
  myBookTalksIcon: 'myBookTalksIcon.svg',
  myNewsChannelsIcon: 'myNewsChannelsIcon.svg',
  theater: 'theater.svg',
  trending: 'trending.svg',
  returnRed: 'returnRed.svg',
  mazePassage: 'mazePassage.svg',
  pinned: 'pinned.svg',
  unpinned: 'unpinned.svg',
  blueUpArrow: 'blueUpArrow.svg',
  orangeDownArrow: 'orangeDownArrow.svg',
  settingsColored: 'settingsColored.svg',
  aiStar: 'aiStar.svg',
  sun: 'sun.svg',
  moon: 'moon.svg',
  sepia: 'sepia.svg',
  textBoldness: 'textBoldness.svg',
  font: 'font.svg',
  lineSpacing: 'lineSpacing.svg',
  letterSpacing: 'letterSpacing.svg',
  unlock: 'unlock.svg',
  expand: 'expand.svg',
  assignedAssignment: 'assignedAssignment.svg',
};

export type IconTypes = keyof typeof icons;
