import { createStandaloneToast } from '@chakra-ui/toast';
import crypto from 'crypto';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import weekday from 'dayjs/plugin/weekday';
import debounce from 'debounce-promise';
import jwtDecode from 'jwt-decode';
import Router from 'next/router';
import isBetween from 'dayjs/plugin/isBetween';
import { ValidateResult } from 'react-hook-form';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import matches from 'validator/lib/matches';
import { isString } from 'lodash';
import { FriendsStatusDtoType } from '@/store/friends/types';
import { IconOptionsType } from '@/components/common/Icon/types';

import { adaptiveExamIndexes } from '@/components/common/AdaptiveExam/constants';
import { Passage } from '@/components/teacher/ExamBuilder/types';
import { ContentProps } from '@/store/teacher-exam/types';
import {
  FormattedLearnerDto,
  LearnerProfileDto,
} from '@/store/user-profile/types';
import {
  BackEndExamType,
  ExamSections,
  LearnerActionsType,
  LearnerBEStatusType,
  LearnerStatusType,
  PasswordStateType,
  PaymentMethodDetailsType,
  ReactSelectExamType,
  SubjectKey,
  ToastType,
  UserType,
} from '../types/common';
import {
  request,
  requestWithAccessToken,
  requestWithoutAccessToken,
} from './apiRequests/userRequest';
import { getEnvVars } from './common';
import { logger } from './logger';
import { EventContent } from '@/components/teacher/Dashboard/types';
import { EventsDto } from '@/store/classwork-details/types';
import { ResourceSection } from '@/store/deep-reader/types';

dayjs.extend(isBetween);

export const debounceAsyncFunc = debounce(
  async (func: (...args: unknown[]) => Promise<unknown>) => {
    return func();
  },
  500
);

export const namePattern = /^[A-Za-z\-_ ']+$/;

export const isValidStartDate = (value?: string): ValidateResult => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return new Date(value as string) >= today ? true : 'Invalid start date';
};

export const isValidDueDate = (
  value?: string,
  startDate?: string
): ValidateResult =>
  new Date(value as string) > new Date(startDate as string)
    ? true
    : 'Invalid due date';

export const isValidDateOfBirth = (value: string): ValidateResult => {
  let result: ValidateResult = true;

  if (new Date(value) < new Date() && new Date(value) > new Date(1900, 0, 1)) {
    result = true;
  } else {
    result = 'Invalid date of birth';
  }

  return result;
};

export const isValidEmail = (value?: string): ValidateResult =>
  value?.trim() ? isEmail(value) : true;

export const isEmailTaken = async (
  providedEmail: string,
  currentEmail: string
) => {
  let result: ValidateResult = true;
  if (currentEmail !== providedEmail && providedEmail.trim() !== '') {
    try {
      const response = await requestWithoutAccessToken(
        `profile/check-email/${providedEmail}`
      );

      if (response.errorMessage) {
        result = response.errorMessage;
      } else if (response.isTaken) {
        result = 'This email is already taken. Please try another';
      }
    } catch (error) {
      logger.error('error:', error);
    }
  }
  return result;
};

export const isPhoneTaken = async (
  providedPhone: string,
  currentPhone: string
) => {
  let result: ValidateResult = true;
  if (currentPhone !== providedPhone) {
    try {
      const response = await requestWithoutAccessToken(
        `profile/check-phone/${providedPhone}`
      );

      if (response.errorMessage) {
        result = response.errorMessage;
      } else if (response.isTaken) {
        result = 'This mobile number is already taken. Please try another';
      }
    } catch (error) {
      logger.error('error:', error);
    }
  }
  return result;
};

export const isValidPhone = (value: string): ValidateResult => {
  let result: ValidateResult = true;
  if (!isMobilePhone(value, 'any')) {
    result = 'The phone you provided is not valid';
  }
  return result;
};

export const isValidPassword = (
  value: UserType['password']
): ValidateResult => {
  let result: ValidateResult = true;
  if (
    value &&
    !matches(value, /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d\W]*$/)
  ) {
    result = 'The password you provided does not meet the requirements';
  }
  return result;
};

export const isMatchPassword = (
  password: UserType['password'],
  confirmPassword: UserType['password']
) => {
  let result: ValidateResult = true;
  if (password !== confirmPassword) {
    result = "Passwords don't match";
  }
  return result;
};

export const getStatusText = (status: string) => {
  const statusText: { [key: string]: string } = {
    STUDYING: 'Studying',
    DEEPREADING: 'Deep Reading',
    TESTING: 'Testing',
    RELAXING: 'Relaxing',
    ONBOARDING: 'Onboarding',
  };

  return statusText[status || 'relaxing'];
};

const { toast } = createStandaloneToast();

export const showToast = (
  title: string,
  description: string,
  status: 'error' | 'info' | 'success' | 'warning',
  duration?: number
) => {
  toast({
    position: 'top',
    title,
    description,
    status,
    duration: duration ?? 6000,
    isClosable: true,
  });
};

type SocialActionConfigType = {
  type: string;
  path: string;
  method: 'POST' | 'PATCH' | 'DELETE';
  toastInfo?: ToastType;
};

export const socialActionsConfig = (
  action: LearnerActionsType,
  uniqueId: string
) => {
  const actionsConfig: {
    [key: string]: SocialActionConfigType;
  } = {
    addFriend: {
      type: action,
      path: `friends/request/${uniqueId}`,
      method: 'POST',
      toastInfo: {
        title: 'Invitation sent',
        description: "You've successfully sent the friend invitation.",
        status: 'success',
      },
    },
    cancelSentInvitation: {
      type: action,
      path: `friends/request/${uniqueId}/revoke`,
      method: 'PATCH',
      toastInfo: {
        title: 'Invitation canceled',
        description:
          'You can add new friends from quick add or from global search.',
        status: 'success',
      },
    },
    acceptFriend: {
      type: action,
      path: `friends/request/${uniqueId}/accept`,
      method: 'PATCH',
      toastInfo: {
        title: 'Invitation accepted',
        description: 'You can find you new friend under my friends tab.',
        status: 'success',
      },
    },
    declineFriendInvitation: {
      type: action,
      path: `friends/request/${uniqueId}/decline`,
      method: 'PATCH',
      toastInfo: {
        title: 'Friend invitation declined',
        description:
          'You can add new friends from quick add or from global search.',
        status: 'success',
      },
    },
    removeFriend: {
      type: action,
      path: `friends/${uniqueId}`,
      method: 'DELETE',
      toastInfo: {
        title: 'Friend has been removed from the friend list.',
        description:
          'You can find the learner again under Quick Add or Global search.',
        status: 'success',
      },
    },
    pinFriend: {
      type: action,
      path: `friends/${uniqueId}/pin`,
      method: 'PATCH',
    },
    unPinFriend: {
      type: action,
      path: `friends/${uniqueId}/unpin`,
      method: 'PATCH',
    },
    hideLearner: {
      type: action,
      path: `profile/${uniqueId}/hide`,
      method: 'PATCH',
      toastInfo: {
        title: 'Friend hidden',
        description: '',
        status: 'success',
      },
    },
    unHideLearner: {
      type: action,
      path: `profile/${uniqueId}/unhide`,
      method: 'PATCH',
      toastInfo: {
        title: 'Friend unhidden',
        description: '',
        status: 'success',
      },
    },
    blockLearner: {
      type: action,
      path: `profile/${uniqueId}/block`,
      method: 'PATCH',
      toastInfo: {
        title: 'Learner blocked',
        description: 'You can see your blocked learners under your profile',
        status: 'success',
      },
    },
    unBlockLearner: {
      type: action,
      path: `profile/${uniqueId}/unblock`,
      method: 'PATCH',
      toastInfo: {
        title: 'Learner removed from block list',
        description: '',
        status: 'success',
      },
    },
    reportLearner: {
      type: action,
      path: `friends/${uniqueId}/unpin`,
      method: 'PATCH',
      toastInfo: {
        title: 'Friend unpinned',
        description: '',
        status: 'success',
      },
    },
  };

  return actionsConfig[action];
};

export const findPasswordState = (
  type: 'registration' | 'login',
  isPasswordProvided: boolean,
  isPasswordInvalid: boolean
): PasswordStateType => {
  let result;

  if (type === 'registration') {
    if (isPasswordProvided && isPasswordInvalid) {
      result = 'providedAndInvalid';
    } else if (isPasswordProvided && !isPasswordInvalid) {
      result = 'providedAndValid';
    } else if (!isPasswordProvided && isPasswordInvalid) {
      result = 'required';
    } else {
      result = 'notProvided';
    }
  }

  if (type === 'login') {
    if (!isPasswordProvided && isPasswordInvalid) {
      result = 'required';
    }
  }

  return result as PasswordStateType;
};

// we formal an array of learners to match BE requirements
export const formatLearnersToMatchBE = (
  rawLearners: LearnerProfileDto[]
): FormattedLearnerDto[] => {
  const formattedLearners = rawLearners.map((l: LearnerProfileDto) => {
    return {
      givenName: l.givenName,
      surname: l.surname,
      dob: l.dob,
      currentSchool: l.currentSchool,
      grade: l.grade,
      gender: l.gender,
      referralCode: l.referralCode,
      targetSchools: l.targetSchools,
      exams: l.exams.some((e) => e.code === 'NO_ASSESSMENT') ? [] : l.exams,
      isHomeschooled: l.isHomeschooled,
      isEnglishSecondLanguage: l.isEnglishSecondLanguage,
      specialEducationNeeds: [
        l.dyslexia && 'dyslexia',
        l.dyspraxia && 'dyspraxia',
        l.otherSpecialNeedsInput,
      ].filter((i) => i),
      isNotificationsEnable: Boolean(l.receiveNotificationsPermission),
      permissions: l.permissions?.length
        ? l.permissions
        : [
            l.studyWithFriendsPermission ? 'canStudyWithFriends' : '',
            l.uploadPhotoPermission ? 'canUploadProfilePicture' : '',
            l.useRealNamePermission ? 'canUseRealName' : '',
            l.receiveNotificationsPermission ? 'canReceiveNotifications' : '',
          ].filter(Boolean),
      email: l.email,
      plan: l.planDetails.plan,
      ...(l?.donation?.amount && {
        donation: {
          school: l.donation.school,
          amount: Number(l.donation.amount) || 0,
          currency: l.donation.currency,
        },
      }),
    };
  });

  return formattedLearners as FormattedLearnerDto[];
};

// TODO MOVE THIS TO SERVICES
export const saveLearnersToDataBase = async (
  formattedLearners: FormattedLearnerDto[],
  paymentMethodId: string,
  discountCoupon = ''
) => {
  const response = await requestWithAccessToken(
    `profile/learners?paymentMethod=${
      paymentMethodId ?? ''
    }&coupon=${discountCoupon}`,
    { learners: formattedLearners },
    'POST'
  );

  return response;
};

export const formatSectionsData = (sections: ExamSections[]) => {
  const sectionsData = sections?.map((section) => [
    `${section.questions ? section.questions : '-'} `,
    ` ${section.minutes ? section.minutes : '-'} min`,
    `${section.name ? section.name : '-'} `,
  ]);

  return sectionsData;
};

export const getRandomStatus = () => {
  const statuses = ['studying', 'deepReading', 'testing', 'relaxing'];
  return statuses[Math.floor(Math.random() * statuses.length)];
};

export const getExamsFullName = (
  examsList: ReactSelectExamType[],
  examCode: string
) => {
  return examsList.find((item) => item.value === examCode)?.label;
};

export const getExamName = (examsList: BackEndExamType[], examCode: string) => {
  return examsList.find((item) => item.code === examCode)?.code;
};

export const getLearner = (
  learnersList: LearnerProfileDto[],
  subLearnerId: string
) => {
  return learnersList.find((l) => l.userId === subLearnerId);
};

export const getLearnerStatus = (
  learnersStatus: LearnerBEStatusType[],
  subLearnerId: string
) => {
  return learnersStatus.find((l) => l.userId === subLearnerId);
};

export const modeToStatusMapping: { [key: string]: LearnerStatusType } = {
  STUDYING: 'studying',
  TESTING: 'testing',
  RELAXING: 'relaxing',
  ONBOARDING: 'onboarding',
  DEEPREADING: 'deepReading',
};

export const getPaymentMethods = async (): Promise<
  PaymentMethodDetailsType[]
> => {
  let paymentMethodsList: PaymentMethodDetailsType[];
  try {
    const paymentMethodsResponse = await requestWithAccessToken(
      'profile/account/payment-methods'
    );

    const isParentFirstPaymentOrHasNoPayments =
      paymentMethodsResponse.statusCode === 404;

    const isUnableToGetPayments =
      paymentMethodsResponse.statusCode > 299 &&
      paymentMethodsResponse.statusCode !== 404;

    if (isParentFirstPaymentOrHasNoPayments) {
      paymentMethodsList = [];
    } else if (isUnableToGetPayments) {
      showToast(
        `${paymentMethodsResponse.errorMessage}`,
        'Please try again later',
        'error'
      );
      paymentMethodsList = [];
    } else {
      paymentMethodsList = paymentMethodsResponse;
    }
  } catch (error) {
    showToast(
      `Unable to get payment methods`,
      'Please try again later',
      'error'
    );
    paymentMethodsList = [];
  }
  return paymentMethodsList;
};

export const formatDateToMatchInput = (date: string) => {
  const dateArray = date.split('T').splice(0, 1)[0].split('/');

  const finalDate = `${dateArray[0]}`;

  return finalDate;
};

export const formatDateToShow = (date: string, countryCode?: string) => {
  const tempDate = new Date(date);

  const dateFixed =
    countryCode === 'US'
      ? `${
          tempDate.getMonth() + 1
        }/${tempDate.getDate()}/${tempDate.getFullYear()}`
      : `${tempDate.getDate()}/${
          tempDate.getMonth() + 1
        }/${tempDate.getFullYear()}`;

  return dateFixed;
};

export const formatDateWithDots = (dateString: string) => {
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  } as const;
  const formattedDate = new Date(dateString).toLocaleDateString(
    'en-GB',
    options
  );

  const [day, month, year] = formattedDate.split('/');
  return `${day}.${month}.${year}`;
};
export const formatDateWithDotsNonTime = (dateString: string) => {
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  } as const;
  const formattedDate = new Date(dateString.split('T')[0]).toLocaleDateString(
    'en-GB',
    options
  );

  const [day, month, year] = formattedDate.split('/');
  return `${day}.${month}.${year}`;
};

// Is this the right place for this function ?
export const getNewAccessToken = async (refreshToken: string) => {
  let result = '';
  try {
    const response = await request('auth/refresh', refreshToken, false, 'GET');

    if (response.ok) {
      const responseJson = await response.json();
      const { accessToken } = responseJson;

      result = accessToken;
    }
  } catch (error) {
    showToast('Something went wrong', `${error}`, 'error');
  }
  return result;
};

// TODO: DELETE
// TO BE DELETED
export const decodeAccessToken = (accessToken: string) => {
  const isUserLoggedIn = Boolean(accessToken);
  const {
    permissions,
    loginProvider,
    roles,
    isCompleted,
    isOnboarding,
    profilePictureUrl,
    loginOrigin,
    sub,
  } =
    (((isUserLoggedIn && jwtDecode(accessToken)) || {
      loginProvider: 'local',
      roles: [],
    }) as any) || {};

  return {
    isUserLoggedIn,
    roles,
    loginProvider,
    isCompleted,
    isOnboarding,
    profilePictureUrl,
    loginOrigin,
    permissions,
    sub,
  };
};

// TODO: DELETE
// TO BE DELETED
export const getUserFromLocalStorage = () => {
  const localUserValue = localStorage.getItem('user');
  if (!localUserValue) {
    return null;
  }
  try {
    const parsedUser = JSON.parse(localUserValue);
    return parsedUser;
  } catch (e) {
    logger.error('Failed to fetch user from local storage');
    return null;
  }
};

export const getScrollPosition = (
  element: React.RefObject<HTMLElement> | null
) => {
  let scrollPosition = { x: 0, y: 0 };
  const isBrowser = typeof window !== 'undefined';

  if (isBrowser && element) {
    const target = element ? element.current : document.body;
    const position = target?.getBoundingClientRect();

    if (position) {
      scrollPosition = {
        x: position?.left,
        y: position?.top,
      };
    }
  }

  return scrollPosition;
};

export const capitalizeFirstLetter = (string: string) => {
  if (string && string.length > 0) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string;
};

export const getExamTimeSpent = (timeToCalculate: number) => {
  const minutes = Math.floor(timeToCalculate / 60);
  const seconds = Math.floor(timeToCalculate % 60);
  return `${minutes}m:${seconds}s`;
};

export const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const getPercentageScore = (correct: number, total: number) => {
  return Math.round((correct / total) * 100);
};

const randomizedTitles = [
  'Legend',
  'Relentless',
  'Boss',
  'Superstar',
  'Ace',
  'Supreme',
  'Unstoppable',
  'Hero',
  'Expert',
  'Titan',
  'Genius',
];

export const getRandomSuccessTitles = () => {
  return `${
    randomizedTitles[getRandomNumber(0, randomizedTitles.length - 1)]
  }! You’re closing the Preparation Gap.`;
};

// Leave this function for now. It calculates the percentage of each item in an array
export const getPercentageArray = (total: number, array: number[]) => {
  const percentageArray = array.map((item) => {
    return Math.round((item / total) * 100);
  });
  return percentageArray;
};

export const getRelativeTime = (date1: string, date2: Date | false) => {
  // calculate the difference in various time units

  if (date2 === false) return 'Never';

  const diffInSeconds = dayjs(date1).diff(dayjs(date2), 'seconds');
  const diffInMinutes = dayjs(date1).diff(dayjs(date2), 'minutes');
  const diffInHours = dayjs(date1).diff(dayjs(date2), 'hours');
  const diffInDays = dayjs(date1).diff(dayjs(date2), 'days');

  // return a string that describes the difference in time
  if (diffInSeconds < 60) {
    return `${diffInSeconds} ${diffInSeconds === 1 ? 'minute' : 'seconds'} ago`;
  }
  if (diffInMinutes < 60) {
    return `${diffInMinutes} ${diffInMinutes === 1 ? 'minute' : 'minutes'} ago`;
  }
  if (diffInHours < 24) {
    return `${diffInHours} ${diffInHours === 1 ? 'hour' : 'hours'} ago`;
  }
  return `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'} ago`;
};

export const getColor = (
  date1: string,
  date2: false | Date
): keyof IconOptionsType['color'] => {
  // Convert the difference to seconds.
  if (date2 === false) return 'gray600';

  const diffInSeconds = dayjs(date1).diff(dayjs(date2), 'seconds');

  // If the difference is less than 1 hour, return "grey".
  if (diffInSeconds < 3600) {
    return 'gray600';
  }
  // If the difference is less than 1 day, return "orange".
  if (diffInSeconds < 86400) {
    return 'primary700';
  }
  return 'error700';
};

export const isUpdatedByDataScience = (
  science: string | false,
  session: undefined | string
) => {
  if (!session || science === false) {
    return false;
  }
  return dayjs(science).isAfter(dayjs(session));
};

export const getProfileImageUrl = (
  profilePictureName: string,
  size: string,
  type?: 'default' | 'resized'
) => {
  const { MEDIA_URL } = getEnvVars();
  const extension = profilePictureName?.substring(
    profilePictureName.lastIndexOf('.') + 1
  );

  const imageName = profilePictureName?.substring(
    0,
    profilePictureName.lastIndexOf('.')
  );

  const dynamicUrl =
    type === 'default' && !profilePictureName?.includes('default')
      ? `${MEDIA_URL}/${profilePictureName}`
      : `${MEDIA_URL}/${imageName}@${size}.${extension}`;

  return profilePictureName
    ? dynamicUrl
    : `${MEDIA_URL}/default_smile_1@${size}.png`;
};

export const getAvatarProfileStatus = (statusResponse: FriendsStatusDtoType) =>
  modeToStatusMapping[statusResponse?.mode || 'RELAXING'];

// in case you want to persist a url param from one page to another
// you can use this function by providing the pathNameToRedirect, the url param you want to persist and the
// possible values it might have
export const persistUrlParam = (
  pathNameToRedirect: string,
  urlParamToFind: string,
  parametersToCheck: string[]
) => {
  const queryParam = Router.query[urlParamToFind];
  const stringQueryParam = queryParam?.toString() || '';
  const isQueryValid = parametersToCheck.includes(stringQueryParam);
  const queryObject = {
    [urlParamToFind]: stringQueryParam,
  };

  if (isQueryValid) {
    Router.push({
      pathname: pathNameToRedirect,
      query: queryObject,
    });
  } else {
    Router.push(pathNameToRedirect);
  }
};

// convert a learner received from the BE to match front end requirements
export const convertToLearnerType = (
  learner: LearnerProfileDto
): LearnerProfileDto => {
  const formattedLearner = {
    ...learner,
    dob: learner.dob.toString(),
    hasSpecialEducationNeeds: Boolean(learner.specialEducationNeeds?.length),
    dyslexia: learner.specialEducationNeeds?.includes('dyslexia'),
    dyspraxia: learner.specialEducationNeeds?.includes('dyspraxia'),
    otherSpecialNeeds: learner.specialEducationNeeds?.some(
      (i) => i !== 'dyspraxia' && i !== 'dyslexia'
    ),
    otherSpecialNeedsInput:
      learner.specialEducationNeeds?.filter(
        (i) => i !== 'dyspraxia' && i !== 'dyslexia'
      )[0] || '',
    planDetails: {
      plan: learner.plan,
      price: 0,
      features: [],
    },
  };

  return formattedLearner;
};

const getFormattedExams = (
  exams: LearnerProfileDto['planDetails']['features']
): ReactSelectExamType[] => {
  if (exams) {
    const formattedExams = exams.map((exam) => ({
      label: exam.name,
      value: exam?.code,
      type: exam?.type.toUpperCase() as ReactSelectExamType['type'],
      date: exam?.date,
    }));

    return [
      ...formattedExams,
      // {
      //   label: 'I only want to prepare for school exams',
      //   value: 'NO_ASSESSMENT',
      //   type: 'NO_ASSESSMENT',
      // },
    ];
  }
  return [];
};

export const getCoreAndPremiumExams = (
  coreFeatures: LearnerProfileDto['planDetails']['features'],
  premiumFeatures: LearnerProfileDto['planDetails']['features']
) => {
  const corePlanExams = coreFeatures ? getFormattedExams(coreFeatures) : [];

  const premiumPlanExams = premiumFeatures
    ? getFormattedExams(premiumFeatures)
    : [];

  return {
    corePlanExams,
    premiumPlanExams,
  };
};

const formatStringToCamelCase = (str: string) => {
  const splitted = str.split('-');
  if (splitted.length === 1) return splitted[0];
  return (
    splitted[0] +
    splitted
      .slice(1)
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join('')
  );
};

// Encrypt a string
export function encrypt(text: string, secretKey: string) {
  const algorithm = 'aes-256-ctr';
  const iv = crypto.randomBytes(16);

  const cipher = crypto.createCipheriv(algorithm, secretKey, iv);

  const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);

  return {
    iv: iv.toString('hex'),
    content: encrypted.toString('hex'),
  };
}

// Decrypt a string
export function decrypt(
  hash: { iv: string; content: string },
  secretKey: string
) {
  const algorithm = 'aes-256-ctr';

  const decipher = crypto.createDecipheriv(
    algorithm,
    secretKey,
    Buffer.from(hash.iv, 'hex')
  );

  const decrpyted = Buffer.concat([
    decipher.update(Buffer.from(hash.content, 'hex')),
    decipher.final(),
  ]);

  return decrpyted.toString();
}

export const clearLocalStorage = (): void => {
  const lastLoginTab = localStorage.getItem('lastLoginTab');
  localStorage.clear();
  if (lastLoginTab) localStorage.setItem('lastLoginTab', lastLoginTab);
};

export const getTextWidth = (
  text: string,
  fontSize: number,
  fontFamily: string
) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (context) {
    context.font = `${fontSize}px ${fontFamily}`;
    return context.measureText(text).width;
  }
  return 0;
};

type environments = 'development' | 'staging' | 'production';

export const isAllowedEnv = (
  ...envs: Array<environments> | Array<Array<environments>>
): boolean => {
  const { NODE_ENV } = getEnvVars();
  return envs.flat().includes(NODE_ENV as environments);
};

export const deepFreeze = <T>(obj: T): Readonly<T> => {
  Object.getOwnPropertyNames(obj).forEach((name: string) => {
    const prop = (obj as any)[name];
    if (typeof prop === 'object' && prop !== null && !Object.isFrozen(prop)) {
      deepFreeze(prop);
    }
  });
  return Object.freeze(obj);
};

export const getPageTypeFromUrl = () => location.pathname?.split('/')[1];

const weekdays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

type Weekday =
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'
  | 'Sunday';

const weekdaysKey = {
  Monday: 'Mo',
  Tuesday: 'Tu',
  Wednesday: 'We',
  Thursday: 'Th',
  Friday: 'Fr',
  Saturday: 'Sa',
  Sunday: 'Su',
};

export const getWeekRange = () => {
  function days(current: Date) {
    const first = current.getDate() - current.getDay() + 1;
    current.setDate(first);

    const week = Array.from(Array(7).keys()).map((idx) => {
      const d = new Date();
      d.setDate(d.getDate() - d.getDay() + idx + 1);

      return {
        date: d,
        day: weekdays[idx],
        formatedDay: dayjs(d).format('DD-MM-YYYY'),
        dayKey: weekdaysKey?.[weekdays?.[idx] as Weekday],
      };
    });

    return week;
  }

  const startWeek = dayjs().startOf('week').toISOString();
  return days(new Date(startWeek));
};

export const getCurrentWeekDate = () => {
  const startOfWeek = dayjs(new Date()).startOf('isoWeek');
  const endOfWeek = dayjs(new Date()).endOf('isoWeek');

  return {
    month: startOfWeek.format('MMM'),
    startDay: startOfWeek.format('D'),
    endDay: endOfWeek.format('D'),
    endMonth: endOfWeek.format('MMM'),
  };
};

export const getMinutesAndSeconds = (duration: number) => {
  const minutes = Math.floor(duration / 60);
  const seconds = duration % 60;

  return { minutes, seconds };
};

export const quizIconByType = {
  multiple_choice: 'multipleChoice',
  highlight_the_answer: 'highlights',
  short_answer: 'shortAnswer',
  poll: 'trueOrFalse',
};

export const removeQueryParam = (param: string) => {
  const { pathname, query } = Router;
  const params = new URLSearchParams(query as any);
  params.delete(param);
  Router.replace({ pathname, query: params.toString() }, undefined, {
    shallow: true,
  });
};

export const getPassageEvents = (
  events: ContentProps[],
  subject?:
    | {
        code: string;
        name: string;
        sections?: any;
      }
    | undefined
): [[string, Passage][], ContentProps[]] => {
  const passages = new Map<string, Passage>();

  const othersAsArray: ContentProps[] = [];

  events?.forEach((event: ContentProps) => {
    if (event?.content?.exhibit?.type === 'HTML') {
      if (!event.content?.exhibit?.passage) {
        othersAsArray.push(event);
      } else if (!passages.has(event.content.exhibit.passage.id)) {
        passages.set(event.content.exhibit.passage.id, {
          title: event.content.exhibit.passage.title,
          content: event.content.exhibit.content,
          events: [event],
          subject,
          event,
        });
      } else {
        const e = passages.get(event.content.exhibit.passage.id);

        if (e) {
          e.events.push(event);
        }
      }
    } else {
      othersAsArray.push(event);
    }
  });

  return [Array.from(passages.entries()), othersAsArray];
};

export const overViewButtonName: { [key in SubjectKey]: string } = {
  ENG: 'View Lesson Overview',
  AILIT: 'View Activity',
  MATH: 'View Activity',
  VR: 'View Lesson Overview',
  NVR: 'View Lesson Overview',
  QR: 'View Lesson Overview',
  SR: 'View Lesson Overview',
  PSP: 'View Lesson Overview',
  CC: 'View Lesson Overview',
  CT: 'View Lesson Overview',
  ENG_11PLUS: 'View Lesson Overview',
  ENG_13PLUS: 'View Lesson Overview',
  MATH_11PLUS: 'View Activity',
  MATH_13PLUS: 'View Activity',
};

dayjs.extend(weekday);
dayjs.extend(isoWeek);

export const getWeekInfo = (weeksAgo: number): string => {
  const currentDate = dayjs();
  const targetDate = currentDate.subtract(weeksAgo, 'week');

  const startDate = targetDate.weekday(1); // Set to Monday
  const dueDate = targetDate.weekday(7); // Set to Sunday

  const startMonth = startDate.format('MMM');
  const startDay = startDate.date();

  const endMonth = dueDate.format('MMM');
  const endDay = dueDate.date();

  let monthDays: string;
  if (startMonth === endMonth) {
    monthDays = `${startMonth} ${startDay}-${endDay}`;
  } else {
    monthDays = `${startMonth} ${startDay} - ${endMonth} ${endDay}`;
  }

  return monthDays;
};

export const checkDayPassed = (targetDate: Date | string | undefined) => {
  if (!targetDate) {
    return false;
  }

  const today = dayjs();
  const dateToCheck = dayjs(targetDate);

  return dateToCheck.isBefore(today, 'day');
};

export const calculateWeeksBeforeDate = (
  targetDate: Date | string | undefined
) => {
  if (!targetDate) {
    return null;
  }

  const today = dayjs();
  const dateToCheck = dayjs(targetDate);

  return dateToCheck.diff(today, 'week') || 1;
};

type AnyObject = { [key: string]: any };

export const removeEmptyPropertiesFromObject = <T extends AnyObject>(
  obj: T
): Partial<T> => {
  const result: Partial<T> = {};
  Object.keys(obj).forEach((key): void => {
    const value = obj[key];
    if (value !== null && value !== undefined && value !== '') {
      if (typeof value === 'object' && !Array.isArray(value)) {
        const nestedResult = removeEmptyPropertiesFromObject(value);
        if (Object.keys(nestedResult).length > 0) {
          result[key as keyof T] = nestedResult as any;
        }
      } else if (Array.isArray(value)) {
        if (value?.length) {
          (result as any)[key as keyof T] = value;
        }
      } else {
        result[key as keyof T] = value;
      }
    }
  });
  return result;
};

type Branch = {
  events: Array<any>;
  scoreMax: number;
  scoreMin: number;
  branches?: Branch[];
};

type AdaptiveData = {
  events: any[];
  branches?: Branch[];
};

export const countTotalAdaptiveEvents = (
  data: AdaptiveData | null,
  branchPath: {
    branchIndex: number;
    level: number;
    score: number;
  }[] = [],
  level = 0
): number => {
  if (!data) return 0;
  const isAlreadyAdded = branchPath?.some((path) => path.level === level);
  const currentLevelEvents = data.events ? data.events.length : 0;

  const firstBranchEvents =
    data.branches && data.branches[0]
      ? countTotalAdaptiveEvents(data.branches[0], branchPath, level + 1)
      : 0;

  return (
    currentLevelEvents +
    firstBranchEvents -
    (isAlreadyAdded ? data.events?.length || 0 : 0)
  );
};

export const getFirstLineEvents = (
  data: AdaptiveData | null,
  index = 0
): (string | EventsDto)[] => {
  if (!data) return [];

  const currentLevelEvents = data.events?.length
    ? data.events?.map((e, eventIndex) => ({
        ...(isString(e) ? { eventId: e } : e),
        branchIndex: index,
        branchEventIndex: eventIndex,
      }))
    : [];

  const firstBranchEvents =
    data.branches && data.branches[0]
      ? getFirstLineEvents(data.branches[0], index + 1)
      : [];

  return [...currentLevelEvents, ...firstBranchEvents];
};

type BranchPath = {
  branchIndex: number;
}[];

export const findBranchByLevel = (
  branches: Branch[] | undefined,
  branchPath: BranchPath,
  searchedLevel: number,
  level = 1
): any[] | undefined => {
  if (!branches) return undefined;

  if (level === searchedLevel) {
    return branches[branchPath[level - 1]?.branchIndex]?.events;
  }

  return findBranchByLevel(
    branches[branchPath[level - 1]?.branchIndex]?.branches,
    branchPath,
    searchedLevel,
    level + 1
  );
};

export const getEventsAtLevelAndIndex = (
  obj: any,
  level: number,
  index: number
) => {
  function traverse(node: any, currentLevel: number) {
    if (currentLevel === level) {
      return node?.events?.[index] || [];
    }
    if (!node.branches) {
      return [];
    }
    return node.branches.flatMap((branch: any) =>
      traverse(branch, currentLevel + 1)
    );
  }

  return traverse(obj, 0);
};

export const splitAdaptiveByBranches = ({
  events = [],
  branchPath = [],
  branches = [],
}: any = {}) => {
  const firstEventIndexes =
    branchPath?.map((path: any) => {
      const branchEvent = findBranchByLevel(branches, branchPath, path.level);

      return isString(branchEvent?.[0])
        ? branchEvent?.[0]
        : branchEvent?.[0]?.eventId;
    }) || [];

  const results = [];
  let currentChunk = [];

  for (let i = 0; i < events.length; i += 1) {
    if (
      firstEventIndexes?.includes(events[i]?.eventId) &&
      currentChunk.length > 0
    ) {
      results.push(currentChunk);
      currentChunk = [];
    }
    currentChunk.push(events[i]);
  }

  if (currentChunk.length > 0) {
    results.push(currentChunk);
  }

  return results;
};

type AdaptiveSectionsParams = {
  events: any;
  branches: any;
  adaptedToBranch?: any;
  isCounter?: any;
  branchPath?: any;
  examCode: string;
};

export const getAdaptiveSections = (
  {
    events: currentEvents,
    branches: currentBranches,
    adaptedToBranch,
    isCounter = false,
    branchPath = [],
    examCode,
  }: AdaptiveSectionsParams = {} as AdaptiveSectionsParams
) => {
  if (!currentEvents?.length) {
    return {
      events: [],
      branches: [],
      totalEvents: 0,
    };
  }

  if (!currentBranches?.length) {
    return {
      events: currentEvents || [],
      passages: [],
      branches: [],
      totalEvents: currentEvents?.length || 0,
    };
  }

  const isPassages =
    isString(examCode) && examCode?.includes('SHRTY')
      ? false
      : currentBranches?.some((b: any) => b?.branches?.length);

  const events = isPassages
    ? currentBranches?.[0]?.events
    : currentEvents || [];

  const passagesEvents = isCounter
    ? currentEvents
    : currentEvents?.filter(
        (event: any) => !events.some((e: any) => e.eventId === event?.eventId)
      );

  const passages = isPassages ? passagesEvents : [];

  const branches = isPassages
    ? currentBranches?.[0]?.branches || []
    : currentBranches || [];

  if (adaptedToBranch) {
    const index =
      adaptiveExamIndexes?.[
        adaptedToBranch as keyof typeof adaptiveExamIndexes
      ];

    return {
      events: currentEvents || [],
      branches,
      totalEvents: countTotalAdaptiveEvents(
        {
          events: currentEvents,
          branches: currentBranches,
        },
        branchPath
      ),
      passages: [],
      passagesLength: isPassages
        ? (currentEvents?.length || 0) -
          (currentBranches?.[0]?.branches?.[index]?.events?.length || 0) -
          (currentBranches?.[0]?.events?.length || 0)
        : 0,
    };
  }

  return {
    events,
    passages,
    branches,
    passagesLength: isPassages ? passages?.length : 0,
    totalEvents: countTotalAdaptiveEvents(
      {
        events: currentEvents,
        branches: currentBranches,
      },
      branchPath
    ),
  };
};

interface Page<T = unknown> {
  results: T[];
}
interface Data<T = unknown> {
  pages: Page<T>[];
}

export const concatPagesResults = <T = unknown>(data: Data<T> | undefined) => {
  return (
    data?.pages
      ?.map((page: Page<T>) => page?.results)
      ?.filter((results: T[]) => results)
      ?.flat() || []
  );
};

export const isTeacherType = (userType: string): boolean => {
  const teacherTypes = [
    'teacher',
    'super-admin',
    'head-of-department',
    'head-of-year',
    'home-teacher',
    'school-teacher',
    'school-admin-plus',
    'school-head-dept',
    'school-head-year',
    'tutor',
    'staff',
  ];

  return teacherTypes.includes(userType);
};

export const checkIsDateBetween = (
  start: string | undefined,
  end: string | undefined
) => {
  if (!start && !end) {
    return false;
  }

  const dateToCheck = dayjs(Date());
  const startDate = dayjs(start);
  const dueDate = dayjs(end);

  return dateToCheck.isBetween(startDate, dueDate, 'day', '[]');
};

export const removeKeysFromObject = <T extends Record<string, any>>(
  obj: T,
  keysToRemove: string[]
): Partial<T> => {
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => !keysToRemove.includes(key))
  ) as Partial<T>;
};

export const splitMessage = (message: string) => {
  if (!message) return '';
  const htmlContent = message.replace(/\n/g, '<br/>');
  const spanWrappedContent = htmlContent
    .split(/(<br\/?>|\s+)/)
    .map((part, index) => {
      if (part.trim() === '' || part.startsWith('<br')) {
        return part;
      }
      return `<span class="reveal-word" style="opacity: 0; display: inline-block;">${part}</span>`;
    })
    .join('');

  return spanWrappedContent;
};

export const findChapterByOrder = (
  chapters: ResourceSection[],
  order: number
): ResourceSection | undefined => {
  return chapters?.reduce<ResourceSection | undefined>((found, chapter) => {
    if (found) return found;
    if (chapter.order === order) return chapter;
    if (chapter.subsections) {
      return findChapterByOrder(chapter.subsections, order);
    }
    return undefined;
  }, undefined);
};
